import styled from 'styled-components';
import AnimationFadeout from '../Animation/AnimationFadeOut';
import Markdown from 'react-markdown'
// плагин для маркдаун, автогенерация ссылок и тд
import remarkGfm from 'remark-gfm'

const DescriptionContainer = styled.div`
    width: 100%;
    max-width: 1170px;    
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
    
    font-size: 1.8rem;
    text-align: center;

    @media (max-width: 1200px) {
        font-size: 1.5rem;
    }         
    @media (max-width: 1023px) {
        font-size: 1.2rem;
    }    

`


const Description = (props) => {


    return (

        <DescriptionContainer>
            <AnimationFadeout >
                <Markdown remarkPlugins={[remarkGfm]}>
                    {props.children}
                </Markdown>
            </AnimationFadeout>
        </DescriptionContainer>

    );
}
 
export default Description;