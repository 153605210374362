import styled from 'styled-components';
import { useState, useEffect } from 'react';
import ProductionItem from './ProductionItem';
import { motion } from 'framer-motion';
import { useFetching } from '../../../hooks/useFetching';
import { mediaUrl } from '../../../SiteConfig';
import { getProduction } from '../../../Api/Production';
import { GroupAnimation, animationOnce } from '../../Animation/config';
import Loader from '../../Loader/Loader'


const ProductionContainer = styled(motion.div)`
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;

`

const Production = () => {
    const [productionList, setProductionList] = useState([]);

    const [fetchProduction, isProductionLoading, productionError] = useFetching(async () => {
        const response = await getProduction();
        setProductionList(response.data.data);
    })

    useEffect(() => {        
        fetchProduction();      
    }, []) 

    return (
        <>
        <ProductionContainer
                initial="hidden"
                whileInView="visible"
                viewport={{ once: animationOnce }}
        >
            {productionList.map(item => (
                <ProductionItem 
                    image={mediaUrl + item.attributes.Poster.data.attributes.formats.small.url}
                    key={item.id}
                    variants={GroupAnimation}
                    custom={item.id + 1}                        
                >
                    {item.attributes.Title}
                </ProductionItem>
            ))}
        </ProductionContainer>
        
        {isProductionLoading &&
            <Loader $marginTop={"30px"}/>
        }
        </>
    );
}
 
export default Production;