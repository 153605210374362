import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import SectionLable from "../components/SectionLable";


function NotFound() {
    return (

        <>
            <BurgerMenu/>
            <Header/>
            <SectionLable margintop='70px' marginbottom='70px'>Страница не найдена</SectionLable> 
            <Footer/>
            <FooterMobile />
        </>        
    );
  }
  
  export default NotFound;