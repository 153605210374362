import styled from 'styled-components';
import ResponsiveImage from '../ResponsiveImage'


const AboutPhotoContainer =  styled.div`
    width: 100%;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;

`

const AboutPhoto = (props) => {
    return (
        <AboutPhotoContainer>
            <ResponsiveImage src={props.img} />
        </AboutPhotoContainer>
    );
}

export default AboutPhoto;