import styled from 'styled-components';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const HeaderMenuWrapper = styled.div`
    justify-self: end;
    font-size: 1.5rem;
    font-family: 'LightFont', sans-serif;    
    letter-spacing: 1px;
    display: none;

    ul {
        list-style: none;
    }
    ul li {
        display: inline;        
    }
    ul > :nth-child(1), ul > :nth-child(2), ul > :nth-child(3), ul > :nth-child(4), ul > :nth-child(5){
        padding-right: 20px;
    }

    @media(min-width:1200px) {
        display: block;
    }    

`

const HeaderMenu = () => {

    return (
        <HeaderMenuWrapper>
            <ul>
                <li><Link to="/">Главная</Link></li>
                <li><Link to="/about/">О компании</Link></li>
                <li><Link to="/produce/">Продукция</Link></li>
                <li><Link to="/production/">Производство</Link></li>
                <li><Link to="/news/">Пресс-центр</Link></li>
                <li><Link to="/contacts/">Контакты</Link></li>
            </ul>
        </HeaderMenuWrapper>
    );
}
 
export default HeaderMenu;