import styled from 'styled-components';

const StyledResponsiveImage = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
`

const ResponsiveImage = (props) => {
    return (
        <StyledResponsiveImage src={props.src} />
    );
}

export default ResponsiveImage;