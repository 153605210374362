import styled from 'styled-components';
import NewsItem from './NewsItem';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { NewsList } from './NewsList';
import { GroupAnimation, animationOnce } from '../../Animation/config';


const NewsContainer = styled(motion.div)`
    width: 100%;
    max-width: 1130px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1129px) {
        max-width: 760px;
    }

    @media (max-width: 767px) {
        max-width: 100%;
        justify-content: center;
    }    

`


const News = (props) => {


    return (
        <NewsContainer
            initial="hidden"
            whileInView="visible"
            viewport={{ once: animationOnce }}
        >
            {NewsList.map(item => (
                <Link 
                    to="/"
                    key={item.id}
                >
                    <NewsItem 
                        image={item.image}
                        variants={GroupAnimation}
                        custom={item.id + 1}                                
                    >
                        {item.title}
                    </NewsItem>
                </Link>
            ))}
        </NewsContainer>

    );
}

export default News;
