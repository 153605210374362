import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";
import About from "../pages/About";
import ProducePage from "../pages/ProducePage";
import ProductionPage from "../pages/ProductionPage";
import ProduceIdPage from "../pages/ProduceIdPage";
import ContactsPage from "../pages/ContactsPage";


const AppRouter = () => {

    return (
        <Routes>      
            <Route path="/" element={<HomePage/>} />
            <Route path="/about/" element={<About/>} />
            <Route path="/produce/" element={<ProducePage/>} />
            <Route path="/produce/:id/" element={<ProduceIdPage/>}/>
            <Route path="/production/" element={<ProductionPage/>} />
            <Route path="/contacts/" element={<ContactsPage/>} />
            <Route path="*" element={<NotFound/>}/>
        </Routes>   
    );
}
 
export default AppRouter;
