import ProduceItem from "./ProduceItem";
import Loader from '../Loader/Loader'
import { useFetching } from '../../hooks/useFetching';
import { mediaUrl } from '../../SiteConfig';
import { useState, useEffect } from 'react';
import { getProduce } from '../../Api/Produce';


const Produeces = () => {
    const [produceList, setProduceList] = useState([]);

    const [fetchProduce, isProduceLoading, produceError] = useFetching(async () => {
        const response = await getProduce();
        setProduceList(response.data.data);
    })

    useEffect(() => {        
        fetchProduce();      
    }, []) 
        
    return (
        <>
        {produceList.map(item => (
            <ProduceItem
                key={item.id}
                id={item.id}
                title={item?.attributes?.Title}
                text={item?.attributes?.Description}
                img={mediaUrl + item?.attributes?.Poster?.data?.attributes?.url}
            />
        ))}

        {isProduceLoading &&
            <Loader $marginTop={"30px"}/>
        }                    
        </>
    );
}

export default Produeces;