import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import SectionLable from "../components/SectionLable";
import Production from "../components/ProductionPage/Production";
import styled from "styled-components";

const Space = styled.div`
    margin-bottom: 70px;
`

function ProductionPage() {
    return (
        <>
            <BurgerMenu/>                
            <Header/>
            <SectionLable margintop='50px'>Производство</SectionLable>
            <SectionLable>Направления деятельности</SectionLable>
            <Production />
            <Space/>
            <Footer/>
            <FooterMobile />
        </>
    );
  }
  
  export default ProductionPage;
