import styled from 'styled-components';
import { Link } from "react-router-dom";


const FooterContainer = styled.div`
    display: none;
    width: 100%;
    height: 260px;
    background-color: #93c238;

    @media (max-width: 1169px) {
        display: block;
    }
`

const FooterContactsWrapper = styled.div`
    display: none;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1169px) {
        display: flex;
    }    
`

const FooterIcon = styled.img`
    
`

const FooterText = styled.div`
    font-size: 1.5rem;
    color: #2a454d;
    padding: 20px 10px;
    text-align: center;

    a {
        color: #2a454d;
    }
    a:hover {
        color: black;
    }    
`

const FooterWrapper = styled.div`
    width: 100%;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: 175px 175px;
`

const FooterLogo = styled.img`
    grid-column: 1 / 3;
    justify-self: center;
    margin-top: 10px;
    margin-bottom: 20px;
`

const FooterLink = styled.div`
    font-size: 1.35rem;    
    padding: 5px;
    
    a {
        color: #2a454d;
    }
`

const FooterLink2 = styled.div`
    font-size: 1.35rem;    
    padding: 5px;
    padding-left: 40px;
    
    a {
        color: #2a454d;
    }
`

const FooterMobile = () => {

    return (
        <>
            <FooterContactsWrapper>
                <FooterIcon src="/images/main/footer/phone.png" />
                <FooterText>
                    <p>+79204010101 +79103576078</p>
                </FooterText>
                <FooterIcon src="/images/main/footer/envelope.png" />
                <FooterText>
                    <a href='mailto:semennoystandart@mail.ru'>semennoystandart@mail.ru</a>
                </FooterText>
                <FooterIcon src="/images/main/footer/geolocation.png" />
                <FooterText>
                    <p>399281 Липецкая область, </p>
                    <p>Хлевенский район, </p>
                    <p>с. Конь-Колодезь, </p>
                    <p>ул. Школьная, владение 21А</p>
                </FooterText>
            </FooterContactsWrapper>
        
            <FooterContainer>
                <FooterWrapper>
                    <FooterLogo src="/images/main/footer/footer_logo.png" />
                    <FooterLink2><Link to="/">Главная</Link></FooterLink2>
                    <FooterLink><Link to="/production/">Производство</Link></FooterLink>
                    <FooterLink2><Link to="/about/">О компании</Link></FooterLink2>
                    <FooterLink><a href="https://press.seed-st.ru">Пресс-центр</a></FooterLink>
                    <FooterLink2><Link to="/produce/">Продукция</Link></FooterLink2>
                    <FooterLink><Link to="/contacts/">Контакты</Link></FooterLink>
                </FooterWrapper>
            </FooterContainer>
        </>
    );
}

export default FooterMobile;