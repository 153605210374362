import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useFetching } from '../../hooks/useFetching';
import { mediaUrl } from '../../SiteConfig';
import { GroupAnimation, animationOnce } from '../Animation/config';
import Loader from '../Loader/Loader';
import { getPartners } from "../../Api/Partners";


const PartnersContainer =  styled.div`
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    
    & div {
        flex: 1 1 200px;
        margin-bottom: 30px;
    }
    

    @media (max-width: 767px) {
        justify-content: center;        

        & div {
            flex: 1 1 100%;
        }        
    }    
`

const PartnerItem = styled.div`
`

const Partners = () => {
    const [partnersList, setPartnersList] = useState([]);

    const [fetchPartners, isPartnersLoading, partnersError] = useFetching(async () => {
        const response = await getPartners();
        setPartnersList(response.data.data);
    })

    useEffect(() => {        
        fetchPartners();      
    }, []) 

    return (
        <>
        <PartnersContainer>
            {partnersList.map(item => (
                <PartnerItem
                    key={item.id}
                >
                    <a 
                        href={item?.attributes?.Link}
                        target='_blank'
                    >
                        <img src={mediaUrl + item?.attributes?.Logo?.data?.attributes?.url}/>
                    </a>
                </PartnerItem>
            ))}

        </PartnersContainer>

        {isPartnersLoading &&
            <Loader $marginTop={"30px"}/>
        }
        </>
    );
}

export default Partners;