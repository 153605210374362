import styled from 'styled-components';
import { motion } from 'framer-motion';


const ProduceItemContainer =  styled(motion.div)`
    width: 100%;
    max-width: 350px; 
    height: 220px;   
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 0;
    background-color: ${props => props.color || "#93c238"};
    position: relative;
`

const ProduceItemLable = styled.div`    
    font-size: 2.018rem;
    text-transform: uppercase;
    font-family: 'BoldFont', sans-serif;
    position: absolute;
    bottom: 30px;
    left: 30px;
    background-color: #93c238;
    padding: 0 5px;
`

const ProduceItemImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 350px;
`

const ProduceItem = ({children, image, ...props}) => {

    return (
        <ProduceItemContainer {...props}>
            <ProduceItemLable>
                {children}                
            </ProduceItemLable>
            {image && 
                <ProduceItemImage src={image}/>
            }
        </ProduceItemContainer>
    );
}
 
export default ProduceItem;