import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import ProduecesId from "../components/ProducePage/ProducesId";


function ProduceIdPage() {
    return (
        <>
            <BurgerMenu/>                
            <Header/>
            <ProduecesId />
            <Footer/>
            <FooterMobile />
        </>
    );
  }
  
  export default ProduceIdPage;
