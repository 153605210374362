import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Description from "../components/HomePage/Description";
import STButton from "../components/STButton";
import SectionLable from "../components/SectionLable";
import Produce from "../components/HomePage/Produce/Produce";
import Production from "../components/HomePage/Production/Production";
import News from "../components/HomePage/News/News";
import ContactsMap from "../components/HomePage/Map/Map";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import HeaderBanner from "../components/Header/HeaderBanner";
import { useState, useEffect } from 'react';
import { useFetching } from '../hooks/useFetching';
import { mediaUrl } from '../SiteConfig';
import Loader from '../components/Loader/Loader';
import { getHomePage } from "../Api/HomePage";


function HomePage() {
    const [homePage, setHomePage] = useState([]);

    const [fetchHomePage, isHomePageLoading, homePageError] = useFetching(async () => {
        const response = await getHomePage();
        setHomePage(response.data.data);
    })

    useEffect(() => {        
        fetchHomePage();
    }, []) 


    return (
        <>
            <BurgerMenu/>                
            <Header/>
            <HeaderBanner />
            <Description>
                {homePage?.attributes?.Text1}
            </Description>
            <STButton to='/'>Бесплатная консультация</STButton>
            <SectionLable margintop='70px'>Продукция</SectionLable>
            <Produce/>
            <STButton to='/produce/'>Узнать больше</STButton>
            <SectionLable margintop='70px'>Производство</SectionLable>
            <SectionLable margintop='10px'>Направления деятельности</SectionLable>
            <Production />
            <STButton to='/production/' marginTop='40px'>Узнать подробнее</STButton>
            <SectionLable margintop='70px'>Новости</SectionLable>            
            <News />
            <STButton to='/news/' marginTop='40px'>Больше новостей</STButton>
            <ContactsMap/>
            <Footer/>
            <FooterMobile />
        </>
    );
  }
  
  export default HomePage;
