import styled from 'styled-components';
import { YMaps, Map, Placemark, FullscreenControl } from '@pbe/react-yandex-maps';


const MapContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    margin-top: 50px;
    margin-bottom: 40px;
`

const ContactsMap = () => {

    const defaultState = {
        center: [52.141561, 39.162549],
        zoom: 14,
        behaviors: ["disable('scrollZoom')"]
    };

    return (
        <MapContainer id="map">
            <YMaps>
            <Map defaultState={defaultState} width="100%" height="500px">
                <Placemark geometry={[52.143313, 39.170247]} />
                <FullscreenControl />
            </Map>
            </YMaps>            
        </MapContainer>
    );
}

export default ContactsMap;