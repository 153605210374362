import styled from 'styled-components';
import { motion } from 'framer-motion';


const StyledSectionLable = styled(motion.div)`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${props => props.marginbottom || "0"};
    margin-top: ${props => props.margintop || "0"};
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;   
    font-size: 2.018rem;
    text-align: center;
    font-family: 'BoldFont', sans-serif;
`

const SectionLable = (props) => {

    return (
        <StyledSectionLable 
            {...props} 
            initial={{ opacity: 0, scale: 0.5}}
            whileInView={{ opacity: 1, scale: 1}}
            viewport={{ once: true }}
            transition={{delay: 0.3}}
        />
    );
}
 
export default SectionLable;
