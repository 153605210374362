import styled from 'styled-components';
import { motion } from 'framer-motion';


const ProductionItemContainer = styled(motion.div)`
    display: grid;
    grid-template-columns: 2fr 1fr;
    background-color: #93c238;
    max-height: 125px;
    margin-top: 20px;    
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        max-width: 350px;
        margin-top: 30px;
    }       
`

const ProductionItemLable = styled.div`
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 5px;
    font-size: 2.0175rem;
    text-transform: uppercase;
    
    @media (max-width: 767px) {
        font-size: 1rem;
        padding: 5px;
    }    
`

const ProductionItemImage = styled.img`
    width: 100%;
    height: auto;
  
`


const ProductionItem = ({children, image, ...props}) => {

    return (
        <ProductionItemContainer {...props}>
            <ProductionItemLable>
                <p>{children}</p>
            </ProductionItemLable>
            <ProductionItemImage src={image} />
        </ProductionItemContainer>
    );
}
 
export default ProductionItem;
