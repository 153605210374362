import {createGlobalStyle} from 'styled-components'


export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'MainFont';
        src: url('/fonts/MyriadPro-regular.otf') format('opentype');
    }
    @font-face {
        font-family: 'BoldFont';
        src: url('/fonts/MyriadPro-bold.otf') format('opentype');
    }
    @font-face {
        font-family: 'LightFont';
        src: url('/fonts/MyriadPro-Light.otf') format('opentype');
    }    

    *, *:before, *:after {
        box-sizing: border-box;    
        margin: 0;
        padding: 0;
    }
    html {
        scroll-behavior: smooth;
        
    }
    body {
        font-family: 'MainFont', sans-serif;
        padding: 0;
        margin: 0;
    }
    html, body {
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
        color: #000;
    }
    a:hover {
        color: white;
    }

`

