import styled from 'styled-components';
import { Link } from "react-router-dom";


const ProduceItemContainer =  styled.div`
    width: 100%;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 719px) {

    }    
`

const ProduceItemWrapper =  styled.div`
    width: 100%;
    background-color: #d8e9b7;
    min-height: 290px;

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }      
`

const ItemImg = styled.img`
    float: left;
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: 10px;
    
    @media (max-width: 767px) {
        margin-right: 0;
    }    
`

const ItemTitle = styled.p`
    text-align: center;
    text-transform: uppercase;
    font-size: 1.8rem;
    padding-top: 30px;
`

const ItemDescription = styled.p`
    text-align: justify;
    font-size: 1.8rem;
    padding: 10px;
    padding-bottom: 40px;

    @media (max-width: 767px) {
        font-size: 1.3rem;
    }
`

const ItemButton = styled.div`
    background-color: #93c238;
    text-align: center;
    color: white;
    padding: 10px;
    text-transform: uppercase;
    font-size: 1.8rem;
`


const ProduceItem = (props) => {
    return (        
        <ProduceItemContainer>
            <ProduceItemWrapper>
                <ItemImg src={props.img} />
                <ItemTitle>{props.title}</ItemTitle>
                <ItemDescription>{props.text}</ItemDescription>                
            </ProduceItemWrapper>
            <Link to={`/produce/${props.id}/#top`}>
                <ItemButton>Узнать о сортах...</ItemButton>
            </Link>
        </ProduceItemContainer>
    );
}

export default ProduceItem;
