import styled from 'styled-components';
import { Link } from "react-router-dom";

const StyledSTButton = styled.div`
    width: 100%;
    max-width: 450px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${props => props.marginBottom || "0"};
    margin-top: ${props => props.marginTop || "0"};
    padding-left: 10px;
    padding-right: 10px;
    background-color: #93c238;
    border-radius: 7px;
    cursor: pointer;
    text-transform: uppercase;
    
    font-size: 1.4rem;
    

    @media (max-width: 470px) {
        max-width: 320px;        
    }         
`

const STButton = (props) => {

    return (
        <StyledSTButton {...props}>
            <Link to={props.to}>
                <p>{props.children}</p>
            </Link>
        </StyledSTButton>
    );
}
 
export default STButton;
