import axios from 'axios';
import { apiUrl } from '../SiteConfig';

// https://docs.strapi.io/dev-docs/api/rest/parameters

export async function getPartners(itemsToLoad = "21") {   
    const url = `${apiUrl}partneries`

    const response = await axios.get(url, {
        params: {
            "fields[0]": "Title",
            "fields[1]": "Link",
            "sort[0]": "id:asc",
            "pagination[pageSize]": itemsToLoad,

            // Чтобы в ответе были картинки, это надо указывать явно, по-умолчанию их не будет
            "populate[0]": "Logo",            
        }
    })
    // console.log(response.data.data)
    return response
}
