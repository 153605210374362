import ProduceIdItem from "./ProduceIdItem";
import { ProduceCategoryLlst } from "./ProduceList";
import SectionLable from "../SectionLable";
import Loader from '../Loader/Loader'
import { useFetching } from '../../hooks/useFetching';
import { mediaUrl } from '../../SiteConfig';
import { useState, useEffect } from 'react';
import { getProduceCategory } from "../../Api/Produce";
import { useParams } from "react-router-dom";


const ProduecesId = () => {
    const params = useParams();
    const [produceCategoryList, setProduceCategoryList] = useState([]);
    const [categoryName, setCategoryName] = useState("");

    const [fetchProduceCategory, isProduceCategoryLoading, produceCategoryError] = useFetching(async () => {
        const response = await getProduceCategory(params.id);
        setCategoryName(response.data.data[0]?.attributes?.produkcziya?.data?.attributes?.Title);
        setProduceCategoryList(response.data.data);
    })

    useEffect(() => {        
        fetchProduceCategory();

    }, []) 

    return (
        <>
            <SectionLable margintop='50px' marginbottom='50px'>{categoryName}</SectionLable>
            {produceCategoryList.map(item => (
                <ProduceIdItem
                    key={item.id}
                    id={item.id}
                    title={item?.attributes?.Title}
                    text={item?.attributes?.Description}
                    img={mediaUrl + item?.attributes?.Poster?.data?.attributes?.url}
                />
            ))}

            {isProduceCategoryLoading &&
                <Loader $marginTop={"30px"}/>
            }
        </>
    );
}

export default ProduecesId;