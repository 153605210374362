import styled from 'styled-components';
import { motion } from 'framer-motion';


const ProductionItemWrapper =  styled.div`
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: ${props => props.bgcolor || "white"};
    margin-bottom: ${props => props.margingbottom || "0"};
`

const ProductionItemHeader =  styled.div`
    width: 100%;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    @media (max-width: 719px) {

    }    

`

const ProductionItemImg = styled.img`
    display: block;
    width: 100%;
    height: auto;    
`

const ProductionItemLabel = styled.div`
    position: absolute;
    top: 0;
    left: 10px;
    background-color: #93c238;
    width: 50%;
    text-transform: uppercase;
    font-size: 2rem;
    padding-left: 40px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
        
    @media (max-width: 767px) {        
        font-size: 1.3rem;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;        
    }        
`

const ProductionItemDescription =  styled(motion.div)`
    width: 100%;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
    font-size: 1.6rem;
    text-indent: 30px;

    @media (max-width: 767px) {
        font-size: 1.3rem;
    }    

`

const ProductionItem = (props) => {
    return (
        <ProductionItemWrapper {...props}>
            <ProductionItemHeader>
                <ProductionItemImg src={props.img}/>
                <ProductionItemLabel>{props.label}</ProductionItemLabel>
            </ProductionItemHeader>
            <ProductionItemDescription
                    initial={{ opacity: 0}}
                    whileInView={{ opacity: 1}}
                    viewport={{ once: true }}
                    transition={{delay: 0.3}}              
            >
                {props.children}
            </ProductionItemDescription>
        </ProductionItemWrapper>
    );
}

export default ProductionItem;
