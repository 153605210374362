import axios from 'axios';
import { apiUrl } from '../SiteConfig';

export async function getHomePage() {   
    const url = `${apiUrl}glavnaya`

    const response = await axios.get(url)
    // console.log(response.data.data)
    return response
}

