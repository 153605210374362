export const NewsList = [
    {
        id: 0,
        title: "Заголовок заголовок заголовокзаголовок загзаголовок заголовок..",
        image: "/images/main/news/1.png"
    },
    {
        id: 1,
        title: "Заголовок заголовок заголовокзаголовок загзаголовок заголовок..",
        image: "/images/main/news/2.png"
    },
    {
        id: 2,
        title: "Заголовок заголовок заголовокзаголовок загзаголовок заголовок..",
        image: "/images/main/news/3.png"
    },
    {
        id: 3,
        title: "Заголовок заголовок заголовокзаголовок загзаголовок заголовок..",
        image: "/images/main/news/4.png"
    },
    {
        id: 4,
        title: "Заголовок заголовок заголовокзаголовок загзаголовок заголовок..",
        image: "/images/main/news/5.png"
    },
    {
        id: 5,
        title: "Заголовок заголовок заголовокзаголовок загзаголовок заголовок..",
        image: "/images/main/news/6.png"
    },                    
]