import axios from 'axios';
import { apiUrl } from '../SiteConfig';

// https://docs.strapi.io/dev-docs/api/rest/parameters

export async function getProduction(itemsToLoad = "10") {   
    const url = `${apiUrl}proizvodstvos`

    const response = await axios.get(url, {
        params: {
            "fields[0]": "Title",
            "fields[1]": "Description",
            "sort[0]": "id:asc",
            "pagination[pageSize]": itemsToLoad,

            // Чтобы в ответе были картинки, это надо указывать явно, по-умолчанию их не будет
            "populate[0]": "Poster",            
        }
    })
    // console.log(response.data.data[0].attributes.Poster.data.attributes.url )
    // console.log(response.data.data[0].attributes.Poster.data.attributes.formats.thumbnail.url)
    // console.log(response.data.data[0].attributes.Poster.data.attributes.formats.medium.url)
    console.log(response.data.data)
    return response
}

