import styled from 'styled-components';
import { motion } from 'framer-motion';
import Markdown from 'react-markdown'
// плагин для маркдаун, автогенерация ссылок и тд
import remarkGfm from 'remark-gfm'


const AboutTextContainer =  styled.div`
    width: 100%;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;

    p {
        margin-bottom: 40px;
        font-size: 1.4rem;
        text-align: justify;    

        @media (max-width: 767px) {
            margin-bottom: 20px;
            font-size: 1.2rem;
            text-align: left;        
        }    
    }

`

const AboutText = (props) => {

    return (
        <AboutTextContainer>
            <Markdown remarkPlugins={[remarkGfm]}>
                {props.children}
            </Markdown>
        </AboutTextContainer>
     );
}

export default AboutText;