import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import ContactsMap from "../components/HomePage/Map/Map";


function ContactsPage() {
    return (

        <>
            <BurgerMenu/>
            <Header/>
            <ContactsMap/>
            <Footer/>
            <FooterMobile />
        </>        
    );
  }
  
  export default ContactsPage;