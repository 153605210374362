import styled from 'styled-components';


const HeaderBannerContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 0;
    background-image: url("/images/main/header_banner.png") !important;    
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    @media (min-height: 768px) {
        height: 570px;
    }      

`


const HeaderBanner = () => {

    return (
        <HeaderBannerContainer/>
    );
}
 
export default HeaderBanner;