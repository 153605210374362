import styled from 'styled-components';
import HeaderMenu from './HeaderMenu';
import HeaderBanner from './HeaderBanner';


const HeaderBackground = styled.div`
    position: relative;
    width: 100%;
    max-width: 1920px;
    min-height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 0;
    background-color: #93c238;
`

const HeaderContainer = styled.div`    
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    margin-top: 0;

`

const HeaderMenuContainer = styled.div`
    height: 120px;
    display: grid;
    grid-template-columns: 230px 1fr;    
    grid-column-gap: 10px;
    align-items: center;
    padding-left: 10px;
`

const LangMenu = styled.div`
    position: absolute;
    top: 45px;
    right: 50px;
    font-size: 1.5rem;
    font-family: 'BoldFont', sans-serif;   
`


const Header = () => {

    return (
        <>
            <HeaderBackground>
                <HeaderContainer>
                    <HeaderMenuContainer>
                        <img src='/images/main/header_logo.png' />
                        <HeaderMenu/>
                    </HeaderMenuContainer>
                </HeaderContainer>
                {/* <LangMenu>
                    RUS ENG
                </LangMenu> */}
            </HeaderBackground>
        </>
    );
}
 
export default Header;