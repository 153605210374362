import styled from 'styled-components';


const FooterContainer = styled.div`
    width: 100%;
    height: 260px;
    background-color: #93c238;

    @media (max-width: 1169px) {
        display: none;
    }
`

const FooterWrapper = styled.div`
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: 500px 75px 575px;
    grid-template-rows: 80px 80px 80px;
`

const FooterDummyDiv = styled.div`    
`

const FooterLogo = styled.img`
    padding-left: 60px;
`

const FooterIcon = styled.img`
    align-self: center;
`

const FooterText = styled.div`
    align-self: center;
    font-size: 1.681875rem;
    color: #2a454d;

    a {
        color: #2a454d;
    }
    a:hover {
        color: white;
    }    
`

const Footer = () => {

    return (
        <FooterContainer id="contacts">
            <FooterWrapper>
                <FooterDummyDiv />
                <FooterIcon src="/images/main/footer/phone.png" />
                <FooterText>
                    <p>+79204010101</p>
                    <p>+79103576078</p>
                </FooterText>
                <FooterLogo src="/images/main/footer/footer_logo.png" />
                <FooterIcon src="/images/main/footer/envelope.png" />
                <FooterText>
                    <a href='mailto:semennoystandart@mail.ru'>semennoystandart@mail.ru</a>
                </FooterText>
                <FooterDummyDiv />
                <FooterIcon src="/images/main/footer/geolocation.png" />
                <FooterText>
                    <p>399281 Липецкая область, Хлевенский район,</p>
                    <p>с. Конь-Колодезь, ул. Школьная, владение 21А</p>
                </FooterText>                
            </FooterWrapper>
        </FooterContainer>
    );
}

export default Footer;
