import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import SectionLable from "../components/SectionLable";
import AboutPhoto from "../components/About/AboutPhoto";
import AboutText from "../components/About/AboutText";
import Partners from "../components/About/Partners";
import { useState, useEffect } from 'react';
import { useFetching } from '../hooks/useFetching';
import { mediaUrl } from '../SiteConfig';
import Loader from '../components/Loader/Loader';
import { getAboutPage } from "../Api/AboutPage";


function About() {
    const [aboutPage, setAboutPage] = useState([]);

    const [fetchAboutPage, isAboutPageLoading, aboutPageError] = useFetching(async () => {
        const response = await getAboutPage();
        setAboutPage(response.data.data);
    })

    useEffect(() => {        
        fetchAboutPage();
    }, []) 

    return (
        <>
            <BurgerMenu/>                
            <Header/>
            <SectionLable margintop='70px'>О компании</SectionLable>
            {isAboutPageLoading &&
                <Loader $marginTop={"30px"}/>
            }
            <AboutPhoto 
                img={mediaUrl + aboutPage?.attributes?.Photo?.data?.attributes?.url}
            />
            <AboutText>
                {aboutPage?.attributes?.Description}
            </AboutText>
            <SectionLable margintop='60px'>Наши партнеры</SectionLable>
            <Partners />
            <Footer/>
            <FooterMobile />
        </>
    );
  }
  
  export default About;
