import axios from 'axios';
import { apiUrl } from '../SiteConfig';

// https://docs.strapi.io/dev-docs/api/rest/parameters

export async function getProduce(itemsToLoad = "21") {   
    const url = `${apiUrl}produkcziyas`

    const response = await axios.get(url, {
        params: {
            "fields[0]": "Title",
            "fields[1]": "Description",
            "sort[0]": "id:asc",
            "pagination[pageSize]": itemsToLoad,

            // Чтобы в ответе были картинки, это надо указывать явно, по-умолчанию их не будет
            "populate[0]": "Poster",            
        }
    })
    console.log(response.data.data)
    return response
}

export async function getProduceCategory(cat_id) {   
    const url = `${apiUrl}sortas`

    const response = await axios.get(url, {
        params: {
            "fields[0]": "Title",
            "fields[1]": "Description",
            "sort[0]": "id:asc",

            // Чтобы в ответе были картинки, это надо указывать явно, по-умолчанию их не будет
            "populate[0]": "Poster",
            "populate[1]": "produkcziya",

            // https://api.seed-st.ru/api/sortas?populate=produkcziya&filters[produkcziya][id][$eq]=1
            // https://docs.strapi.io/dev-docs/api/rest/filters-locale-publication#filtering            
            "filters[produkcziya][id][$eq]": cat_id,
        }
    })
    //console.log(response.data.data)
    return response
}
