import styled from 'styled-components';
import SectionLable from '../SectionLable';
import { motion } from 'framer-motion';


const ProduceIdItemBanner = styled.div`
    width: 100%;
    max-width: 1046px;
    margin-left: auto;
    margin-right: auto;    
    min-height: 104px;

    background-image: ${props => props.background || "none"};
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;    

    background-color: #d8e9b7;
`

const ProduceIdItemContainer =  styled.div`
    width: 100%;
    max-width: 1046px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-top: 30px;
    background-color: #d8e9b7;

    @media (max-width: 767px) {
        margin-bottom: 50px;
    }    

`

const ProduceIdItemDescription = styled(motion.p)`
    text-align: justify;
    font-size: 1.8rem;
    text-indent: 30px; 

    @media (max-width: 767px) {
        font-size: 1.3rem;
    }    
`

const ProduceIdItem = (props) => {
    return (
        <>
            <ProduceIdItemBanner 
                background={ `url("${props.img}") !important`}
            />
            <ProduceIdItemContainer>
                <SectionLable marginbottom="20px">{props.title}</SectionLable>
                <ProduceIdItemDescription
                    initial={{ opacity: 0}}
                    whileInView={{ opacity: 1}}
                    viewport={{ once: true }}
                    transition={{delay: 0.5}}                
                >
                    {props.text}
                </ProduceIdItemDescription>
            </ProduceIdItemContainer>
        </>
    );
}

export default ProduceIdItem;