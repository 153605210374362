import axios from 'axios';
import { apiUrl } from '../SiteConfig';


export async function getAboutPage() {   
    const url = `${apiUrl}o-kompanii`

    const response = await axios.get(url, {
        params: {
            // Чтобы в ответе были картинки, это надо указывать явно, по-умолчанию их не будет
            "populate[0]": "Photo", 
        }
    })
    // console.log(response.data.data)
    return response
}
