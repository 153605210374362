import styled from 'styled-components';
import { motion } from 'framer-motion';


const NewsItemContainer = styled(motion.div)`
    width: 350px;
    min-width: 350px;
    background-color: #93c238;
    margin: 15px 10px;
    transition: .3s ease-in-out;
    border-radius: 8px;
    cursor: pointer;

    @media (min-width: 1280px) {
        &:hover {
            transform: scale(1.05);
        }
    }
`

const NewsItemImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
`

const NewsItemHeader = styled.p`
    color: white;
    padding: 20px;
    font-size: 1.6rem;
    text-align: center;
`

const NewsItem = ({children, image, ...props}) => {

    return (
        <NewsItemContainer {...props}>
            <NewsItemImage src={image} />
            <NewsItemHeader>{children}</NewsItemHeader>
        </NewsItemContainer>
    );
}

export default NewsItem;