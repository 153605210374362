import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Link } from "react-router-dom";


const StyledBurgerMenu = styled.div`
    display: none;

    @media(max-width:1199px) {
        display: block;
    }   

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    float: right;

    /* position: fixed; */
    
    width: 36px;
    height: 30px;    

    right: 30px;
    top: 50px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: darkgreen;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: darkgreen;    
  }

  /* General sidebar styles */
  .bm-menu {
    background: #93c238;
    padding: 2.5em 1.5em 0;
    font-size: 1.5rem;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    font-weight: 400;
    padding-bottom: 10px;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0) !important;
  }
  
  .bm-item {
	  color: #2A454D;
  }
`;


const BurgerMenu = (props) => {
    const [menuOpenState, setMenuOpenState] = useState(false);

    const closeburgerMenu = () => {
        setMenuOpenState(false)
    }

    const handleStateChange = (state) => {
        setMenuOpenState(state.isOpen)
    }

    return(
        <StyledBurgerMenu>
            <Menu 
                isOpen={ menuOpenState }
                onStateChange={(state) => handleStateChange(state)}
            >     
                <Link to="/" onClick={closeburgerMenu}>Главная</Link>
                <Link to="/about/" onClick={closeburgerMenu}>О компании</Link>
                <Link to="/produce/" onClick={closeburgerMenu}>Продукция</Link>
                <Link to="/production/" onClick={closeburgerMenu}>Производство</Link>
                <Link to="/news/" onClick={closeburgerMenu}>Пресс-центр</Link>
                <Link to="/contacts/" onClick={closeburgerMenu}>Контакты</Link>
            </Menu>
        </StyledBurgerMenu>
    );
}
 
export default BurgerMenu;
