import { motion } from "framer-motion";
import { animationOnce } from "./config"


const AnimationFadeOut = (props) => {
    return (
        <motion.div
            initial={{ 
                opacity: 0, 
                scale: 0.5
            }}
            whileInView={{ 
                opacity: 1, 
                scale: 1 
            }}
            viewport={{ once: animationOnce }}
            transition={{ 
                duration: 0.6,
                delay: 0.3
            }}
        >
            {props.children}
        </motion.div>
    )
}
 
export default AnimationFadeOut;