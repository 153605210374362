import Header from "../components/Header/Header";
import BurgerMenu from "../components/Header/BurgerMenu";
import Footer from "../components/Footer/Footer";
import FooterMobile from "../components/Footer/FooterMobile";
import SectionLable from "../components/SectionLable";
import Produeces from "../components/ProducePage/Produces";


function ProducePage() {
    return (
        <>
            <BurgerMenu/>                
            <Header/>
            <SectionLable margintop='70px'>Продукция</SectionLable>
            <Produeces />
            <Footer/>
            <FooterMobile />
        </>
    );
  }
  
  export default ProducePage;
