import styled from 'styled-components';
import ProduceItem from './ProduceItem';
import { motion } from 'framer-motion';
import { GroupAnimation, animationOnce } from '../../Animation/config';
import Loader from '../../Loader/Loader'
import { useFetching } from '../../../hooks/useFetching';
import { mediaUrl } from '../../../SiteConfig';
import { useState, useEffect } from 'react';
import { getProduce } from '../../../Api/Produce';


const ProduceContainer =  styled(motion.div)`
    width: 100%;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 1069px) {
        max-width: 720px;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 719px) {
        max-width: 370px;
        grid-template-columns: 1fr;
    }    

`

const Produce = () => {
    const [produceList, setProduceList] = useState([]);

    const [fetchProduce, isProduceLoading, produceError] = useFetching(async () => {
        const response = await getProduce();
        setProduceList(response.data.data);
    })

    useEffect(() => {        
        fetchProduce();      
    }, []) 

    return (
        <>        
        <ProduceContainer
            initial="hidden"
            whileInView="visible"
            viewport={{ once: animationOnce }}
        >
            { produceList.map(item => (
                <ProduceItem 
                    image={mediaUrl + item?.attributes?.Poster?.data?.attributes?.url}
                    key={item?.id}
                    variants={GroupAnimation}
                    custom={item?.id + 1}                         
                >
                    {item?.attributes?.Title}
                </ProduceItem>
            ))}
        </ProduceContainer>
        
        {isProduceLoading &&
            <Loader $marginTop={"30px"}/>
        }
        </>
    );
}
 
export default Produce;