export const animationOnce = true;

export const GroupAnimation = {
    hidden: {
        opacity: 0, 
        scale: 1, 
        x: 0
    },
    visible: custom => ({
        opacity: 1, 
        scale: 1, 
        x: 0,
        transition: {
            delay: custom * 0.3,
            duration: 1
        }
    })
}
