import { useState, useEffect } from 'react';
import ProductionItem from './ProductionItem';
import { useFetching } from '../../hooks/useFetching';
import { mediaUrl } from '../../SiteConfig';
import { getProduction } from '../../Api/Production';
import Loader from '../Loader/Loader';
import Markdown from 'react-markdown'
// плагин для маркдаун, автогенерация ссылок и тд
import remarkGfm from 'remark-gfm'


const Production = () => {
    const [productionList, setProductionList] = useState([]);

    const [fetchProduction, isProductionLoading, productionError] = useFetching(async () => {
        const response = await getProduction();
        setProductionList(response.data.data);
    })

    useEffect(() => {        
        fetchProduction();      
    }, []) 

    return (
        <>
        {productionList.map(item => (
            <ProductionItem 
                img={mediaUrl + item.attributes.Poster.data.attributes.url}
                key={item.id}
                label={item.attributes.Title}
                // четные/не четные фон красим в разные цвета
                bgcolor={item.id % 2 === 1 ? '' : '#d8e9b7'}
            >
                <Markdown remarkPlugins={[remarkGfm]}>
                    {item.attributes?.Description}
                </Markdown>                
            </ProductionItem>
        ))}

        {isProductionLoading &&
            <Loader $marginTop={"30px"} $marginBottom={"30px"}/>
        }
        </>
    );
}

export default Production;
